<template>
  <div class="relative w-2/3">
    <p class="text-xl absolute top-[-0.6rem] left-2 bg-white px-6 border-3 shadow
      border-[#1e3c53] rounded text-gray-700 capitalize">
      {{ $t('facilityTab.locations') }}
    </p>
    <div class="w-full min-h-[100px] bg-gray-100 mx-auto my-auto rounded-2xl flex flex-col
      justify-center items-center place-items-center place-content-center py-6 gap-3 px-4">
      <SearchBar v-show="this.locations.length || Object.keys(this.locationFilter).length"
        :restoreFromSessionStorage="false"
        :placeholder="this.$t('locationInfoView.filterByLocationName')"
        @update-fetch-params="onFilterUpdated"/>
      <LocationInfoViewCard v-for="location in this.locations" v-bind:key="location.id"
      :currentLocationInfo="location"
      :facilities="this.transitStore.facilities"
      @location-updated="refreshLocationStreamsInfo()" />
      <LocationInfoViewCard
      v-if="this.isAddingLocation"
      :facilities="this.transitStore.facilities"
      @location-updated="refreshLocationStreamsInfo()" />
      <div class="relative w-full justify-center flex">
        <AddSomethingButton
          v-if="!isAddingLocation && this.userManagementStore.hasFacilitiesWritePermission()"
          :buttonText="this.$t('locationInfoView.addLocation')"
          @button-clicked="addLocation()"/>
        <div class="absolute top-1 right-0 text-xs font-bold h-fit">{{ this.$t('facilityTab.mandatoryFields') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import useTransitStore from '@/stores/TransitStore';
import useUserManagementStore from '@/stores/UserManagementStore';
import AddSomethingButton from '@/components/generic/AddSomethingButton.vue';
import LocationInfoViewCard from '@/components/facilityTabComponents/LocationInfoViewCard.vue';
import SearchBar from '@/components/SearchBar.vue';

export default {
  name: 'LocationInfoView',
  props: ['facilityId', 'filteredFacilities'],
  emits: ['filtered-locations'],
  components: {
    AddSomethingButton,
    LocationInfoViewCard,
    SearchBar,
  },
  data() {
    return {
      isAddingLocation: false,
      locationFilter: {},
      locations: [],
    };
  },
  setup() {
    const transitStore = useTransitStore();
    const userManagementStore = useUserManagementStore();
    return {
      transitStore,
      userManagementStore,
    };
  },
  methods: {
    addLocation() {
      this.isAddingLocation = true;
    },
    async refreshLocationStreamsInfo() {
      await this.transitStore.fetchLocation();
      await this.transitStore.fetchStreams();
      this.setLocationsArray();
      this.isAddingLocation = false;
    },
    async onFilterUpdated(locationFilter) {
      this.locationFilter = { ...locationFilter };
      this.setLocationsArray();
    },
    async setLocationsArray() {
      this.locations = [];
      let filteredLocations = [];
      const filteredFacilityIds = [];
      if (this.filteredFacilities.length === 0) {
        this.$emit('filtered-locations', this.locations);
        return;
      }
      this.filteredFacilities?.forEach((facility) => filteredFacilityIds.push(facility.id));
      if (this.locationFilter) {
        this.locationFilter.facilityIds = filteredFacilityIds;
        filteredLocations = await this.transitStore.fetchFilteredLocations(this.locationFilter);
      } else {
        filteredLocations = this.transitStore.locations;
      }
      filteredLocations.forEach((location) => {
        const locationInfo = { ...location };
        locationInfo.streams = this.getStreamsByLocationId(location.id);
        this.locations.push(locationInfo);
      });
      this.$emit('filtered-locations', this.locations);
    },
    getStreamsByLocationId(locationId) {
      const streams = [];
      if (Object.entries(this.transitStore.streams).length <= 0) {
        return streams;
      }
      this.transitStore.streams.forEach((stream) => {
        if (stream.location_ids.includes(locationId)) {
          streams.push(stream);
        }
      });
      return streams;
    },
  },
  async mounted() {
    this.emitter.on('updated_sources', this.refreshLocationStreamsInfo);
  },
  watch: {
    filteredFacilities: 'setLocationsArray',
  },
};
</script>
