<template>
  <div class="relative flex flex-col gap-1">
    <label :htmlFor="this.id" class="pt-2">{{ this.passwordLabel}}</label>
    <input :ref="this.id" name="password" v-model="password"
      :type="showPassword ? 'text' : 'password'" :autocomplete="this.id"
      v-bind:pattern="this.passwordPattern"
      :title="this.$t('inputPassword.passwordFormatTitle', { password_length: this.minPasswordLength })" required
        class="appearance-none relative block w-full px-3
        py-2 border border-gray-300 placeholder-gray-500 text-gray-900
        rounded-md focus:outline-none focus:ring-sky-500 focus:border-sky-500
        focus:z-1 sm:text-sm focus:shadow-inner hover:bg-gray-200 hover:border-1
        hover:border-gray-400" :placeholder="this.placeholder" />
    <div class="absolute bottom-2 right-3 flex items-center cursor-pointer z-2"
      @click="showPassword = !showPassword">
      <img :src="showPassword ? hideEyeImage : showEyeImage"
      :alt="$t('loginPage.togglePasswordVisibility')"
      :title="$t('loginPage.togglePasswordVisibility')"
      class="w-5 h-5">
    </div>
  </div>
</template>

<style scoped>
input:invalid {
    border-color: red;
}

input:valid {
    border-color: green;
}
</style>

<script>
import showEyeImage from '@/assets/img/show-eye.png';
import hideEyeImage from '@/assets/img/hide-eye.png';

export default {
  name: 'InputPassword',
  props: {
    id: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    passwordLabel: {
      type: String,
      required: true,
    },
    minPasswordLength: {
      type: Number,
      required: false,
      default: 8,
    },
  },
  computed: {
    passwordPattern() {
      return `(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{${this.minPasswordLength},}`;
    },
  },
  data() {
    return {
      password: '',
      showPassword: false,
      showEyeImage,
      hideEyeImage,
    };
  },
  methods: {
    getPasswordValue() {
      return this.password;
    },
  },
};
</script>
