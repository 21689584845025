<template>
  <div class="bg-white rounded-2xl px-5 py-3 w-full shadow relative">
    <i class="absolute top-5 left-16 min-w-max h-16">
      <img
        :src="getIcon('securityPolicyMessageIcon')"
        class="w-16 h-16 opacity-95 border border-gray-100 rounded shadow-md"
        alt="security policy icon" />
    </i>
    <div class="grid grid-cols-2 gap-1 items-center place-content-center justify-center justify-items-center py-3">
      <p class="place-self-end p-1 self-center">{{ this.$t('securityPolicyMessageView.securityPolicyMessage') }}:</p>
      <EditableField
        class="place-self-start"
        fieldId="securityPolicyMessage"
        :fieldValidator="setPolicyText"
        :value="this.securityPolicyMessage.policy_message"
        :nonEditable="this.disabled"
        :placeholder="this.$t('securityPolicyMessageView.securityPolicyMessagePlaceholder')"/>
      <p class="place-self-end p-1 self-center">{{ this.$t('securityPolicyMessageView.securityPolicyLink') }}:</p>
      <EditableField
        class="place-self-start"
        fieldId="securityPolicyLink"
        :fieldValidator="setPolicyLink"
        :value="this.securityPolicyMessage.policy_link"
        :nonEditable="this.disabled"
        :placeholder="this.$t('securityPolicyMessageView.securityPolicyLinkPlaceholder')"/>
    </div>
    <hr v-if="this.errorMessage" class="border-t-1 border-dashed border-gray-300 mt-4 mb-4"/>
    <pre v-if="this.errorMessage" class="mt-2 text-red-700 text-sm">{{ this.errorMessage }}</pre>
    <hr v-show="this.areFieldsEdited || !this.currentSecurityPolicyMessage" class="border-t-1 border-dashed border-gray-300 mt-2 mb-1"/>
    <div class="flex gap-6 justify-center" v-show="this.areFieldsEdited || !this.currentSecurityPolicyMessage">
      <button @click="undoSecurityPolicyMessageChanges"
        class="mt-2 px-4 py-2 text-gray-600 hover:font-bold underline underline-offset-4 hover:decoration-4 hover:text-rose-950">
        {{ $t('locationInfoViewCard.cancelChangesButton') }}
      </button>
      <button @click="submitSecurityPolicyMessageChanges"
        class="mt-2 px-3 py-1 bg-sky-500 text-white rounded
          hover:bg-sky-700 transition-colors duration-300 disabled:bg-gray-300"
          :disabled="!this.areFieldsEdited || this.errorMessage.length > 0">
          {{ $t('facilityInfoViewCard.applyChangesButton') }}
      </button>
    </div>
  </div>
</template>

<script>
import { isValidHttpUrl } from '@/utils/urls';
import { getIcon } from '@/utils/icons';
import useUserManagementStore from '@/stores/UserManagementStore';
import EditableField from '@/components/generic/EditableField.vue';

import '@/assets/css/range_slider.css';

export default {
  name: 'SecurityPolicyMessageView',
  components: {
    EditableField,
  },
  data() {
    return {
      securityPolicyMessage: {},
      currentSecurityPolicyMessage: {},
      areFieldsEdited: false,
      errorMessage: '',
      disabled: false,
    };
  },
  setup() {
    const userManagementStore = useUserManagementStore();
    return {
      userManagementStore,
      getIcon,
    };
  },
  methods: {
    setPolicyText(text) {
      this.securityPolicyMessage.policy_message = text;
      this.checkFieldsEdited();
    },
    setPolicyLink(link) {
      this.errorMessage = '';
      if (link && !isValidHttpUrl(link)) {
        this.errorMessage = this.$t('securityPolicyMessageView.urlNonValid');
      }
      this.securityPolicyMessage.policy_link = link;
      this.checkFieldsEdited();
    },
    checkFieldsEdited() {
      this.areFieldsEdited = (JSON.stringify(this.securityPolicyMessage) !== JSON.stringify(this.currentSecurityPolicyMessage));
    },
    async updateSecurityPolicyMessage() {
      try {
        this.currentSecurityPolicyMessage = await this.userManagementStore.fetchSecurityPolicyMessage();
        this.securityPolicyMessage = { ...this.currentSecurityPolicyMessage };
      } catch (error) {
        this.disabled = true;
        this.emitter.emit('show-toast', { toastType: 'error', toastText: this.$t('securityPolicyMessageView.errorOnFetch') });
        console.error(error);
      }
    },
    undoSecurityPolicyMessageChanges() {
      this.securityPolicyMessage = { ...this.currentSecurityPolicyMessage };
      this.areFieldsEdited = false;
    },
    async submitSecurityPolicyMessageChanges() {
      if (!await this.userManagementStore.updateSecurityPolicyMessage(this.securityPolicyMessage)) {
        this.emitter.emit('show-toast', { toastType: 'error', toastText: this.$t('securityPolicyMessageView.errorOnSecurityPolicyMessageUpdate') });
        return;
      }
      await this.updateSecurityPolicyMessage();
      this.areFieldsEdited = false;
      this.emitter.emit('show-toast', { toastType: 'info', toastText: this.$t('securityPolicyMessageView.securityPolicyMessageUpdated') });
    },
  },
  async mounted() {
    await this.updateSecurityPolicyMessage();
  },
};
</script>
