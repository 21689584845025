<template>
  <MultiSelectCombo ref="multiSelectCombo" :buttonText="this.$t('metaObjectTypeFilter.objectTypes')" :uniqueComboName="this.uniqueComboName"
    :comboOptions="this.objectsTypeArray" signalOnChange="update:filterByMetaObjectType" :showAllNoneOptions="true"/>
</template>

<script>
import MultiSelectCombo from './generic/MultiSelectCombo.vue';

export default {
  name: 'MetaObjectTypeFilter',
  components: {
    MultiSelectCombo,
  },
  data() {
    return {
      objectsTypeArray: [],
    };
  },
  props: {
    uniqueComboName: {
      type: String,
      required: true,
    },
  },
  methods: {
    computeObjectTypesArray(objectTypes) {
      this.objectsTypeArray = [];
      Object.entries(objectTypes).forEach(([objectKey, objectLookup]) => {
        if (!objectLookup.hidden) {
          this.objectsTypeArray.push({ id: objectKey, value: this.$t(objectLookup.name) });
        }
      });
    },
    selectAllOptions() {
      this.$refs.multiSelectCombo.selectAll();
    },
  },
};
</script>
