<template>
  <div class="bg-white rounded-2xl px-5 py-3 w-full shadow relative">
      <i class="absolute top-5 left-16 w-16 h-16">
        <img
          :src="getIcon('securityPolicyIcon')"
          class="w-16 h-16 opacity-95 border border-gray-100 rounded shadow-md"
          alt="security policy icon" />
      </i>
      <div class="grid grid-cols-2 gap-1 items-center place-content-center justify-center justify-items-center py-3">
        <p class="place-self-end px-1 items-center">{{ this.$t('securityPolicyView.passwordLength') }}:</p>
        <label htmlfor="pass-length-range"
          :class="['flex justify-center items-center gap-2 place-self-start text-black',
            this.disabled ? 'cursor-not-allowed pointer-events-none' : '']">
          <input id="pass-length-range" type="range"
            :min="MIN_PASSWORD_LENGTH"
            :max="MAX_PASSWORD_LENGTH"
            v-model="this.securityPolicy.min_password_length"
            v-on:mouseup="this.checkFieldsEdited()">
          <input id="pass-length-number" type="number" min=8 max=30 class="w-fit bg-gray-50 rounded-md pl-1"
            v-show="!this.disabled"
            v-model="this.securityPolicy.min_password_length"
            v-on:mouseup="this.checkFieldsEdited()">
          <span class="whitespace-nowrap">
            {{ this.disabled ? this.$t('securityPolicyView.noData') : this.$t('securityPolicyView.characters') }}
          </span>
        </label>
        <p class="place-self-end px-1 items-center">{{ this.$t('securityPolicyView.passwordExpiration') }}:</p>
        <label htmlfor="pass-expiration-range"
          :class="['flex justify-center items-center gap-2 place-self-start text-black',
            this.disabled ? 'cursor-not-allowed pointer-events-none' : '']">
          <input id="pass-expiration-range" type="range"
            :min="MIN_PASSWORD_EXPIRATION"
            :max="MAX_PASSWORD_EXPIRATION"
            v-model="this.securityPolicy.password_expiration_months"
            v-on:mouseup="this.checkFieldsEdited()">
          <input id="pass-expiration-number" type="number"
            :min="MIN_PASSWORD_EXPIRATION"
            :max="MAX_PASSWORD_EXPIRATION"
            class="w-fit bg-gray-50 rounded-md pl-1"
            v-show="!this.disabled"
            v-model="this.securityPolicy.password_expiration_months"
            v-on:mouseup="this.checkFieldsEdited()">
            <span class="whitespace-nowrap">
              {{ this.disabled ? this.$t('securityPolicyView.noData') : this.$t('securityPolicyView.months') }}
            </span>
        </label>
        <p class="place-self-end px-1 items-center">{{ this.$t('securityPolicyView.maxPasswordAttempts') }}:</p>
        <label htmlfor="pass-attempts-range"
          :class="['flex justify-center items-center gap-2 place-self-start text-black',
            this.disabled ? 'cursor-not-allowed pointer-events-none' : '']">
          <input id="pass-attempts-range" type="range"
            :min="MIN_PASSWORD_ATTEMPTS"
            :max="MAX_PASSWORD_ATTEMPTS"
            v-model="this.securityPolicy.max_password_attempts"
            v-on:mouseup="this.checkFieldsEdited()">
          <input id="pass-attempts-number" type="number"
            :min="MIN_PASSWORD_ATTEMPTS"
            :max="MAX_PASSWORD_ATTEMPTS"
            class="w-fit bg-gray-50 rounded-md pl-1"
            v-show="!this.disabled"
            v-model="this.securityPolicy.max_password_attempts"
            v-on:mouseup="this.checkFieldsEdited()">
            <span class="whitespace-nowrap">
              {{ this.disabled ? 'No data' : '' }}
            </span>
        </label>
      </div>
    <hr v-show="this.areFieldsEdited || !this.currentSecurityPolicy" class="border-t-1 border-dashed border-gray-300 mt-2 mb-1"/>
    <div class="flex gap-6 justify-center" v-show="this.areFieldsEdited || !this.currentSecurityPolicy">
      <button @click="undoSecurityPolicyChanges"
        class="mt-2 px-4 py-2 text-gray-600 hover:font-bold underline underline-offset-4 hover:decoration-4 hover:text-rose-950">
        {{ $t('locationInfoViewCard.cancelChangesButton') }}
      </button>
      <button @click="submitSecurityPolicyChanges"
        class="mt-2 px-3 py-1 bg-sky-500 text-white rounded
          hover:bg-sky-700 transition-colors duration-300 disabled:bg-gray-300"
          :disabled="!this.areFieldsEdited">
          {{ $t('facilityInfoViewCard.applyChangesButton') }}
      </button>
    </div>
  </div>
</template>

<script>
import useUserManagementStore from '@/stores/UserManagementStore';
import { getIcon } from '@/utils/icons';
import '@/assets/css/range_slider.css';

const MIN_PASSWORD_LENGTH = 8;
const MAX_PASSWORD_LENGTH = 30;
const MIN_PASSWORD_EXPIRATION = 1;
const MAX_PASSWORD_EXPIRATION = 12;
const MIN_PASSWORD_ATTEMPTS = 3;
const MAX_PASSWORD_ATTEMPTS = 10;

export default {
  name: 'SecurityPolicyView',
  components: {
  },
  data() {
    return {
      securityPolicy: {},
      currentSecurityPolicy: {},
      areFieldsEdited: false,
      disabled: false,
      MIN_PASSWORD_LENGTH,
      MAX_PASSWORD_LENGTH,
      MIN_PASSWORD_EXPIRATION,
      MAX_PASSWORD_EXPIRATION,
      MIN_PASSWORD_ATTEMPTS,
      MAX_PASSWORD_ATTEMPTS,
    };
  },
  setup() {
    const userManagementStore = useUserManagementStore();
    return {
      userManagementStore,
      getIcon,
    };
  },
  methods: {
    checkFieldsEdited() {
      this.securityPolicy.min_password_length = parseInt(this.securityPolicy.min_password_length, 10);
      this.securityPolicy.password_expiration_months = parseInt(this.securityPolicy.password_expiration_months, 10);
      this.securityPolicy.max_password_attempts = parseInt(this.securityPolicy.max_password_attempts, 10);
      this.areFieldsEdited = (JSON.stringify(this.securityPolicy) !== JSON.stringify(this.currentSecurityPolicy));
    },
    async updateSecurityPolicyInfo() {
      try {
        this.currentSecurityPolicy = await this.userManagementStore.fetchSecurityPolicy();
        this.securityPolicy = { ...this.currentSecurityPolicy };
        this.disabled = false;
      } catch (error) {
        this.disabled = true;
        this.emitter.emit('show-toast', { toastType: 'error', toastText: this.$t('securityPolicyView.errorOnFetch') });
        console.error(error);
      }
    },
    undoSecurityPolicyChanges() {
      this.securityPolicy = { ...this.currentSecurityPolicy };
      this.areFieldsEdited = false;
    },
    async submitSecurityPolicyChanges() {
      if (!await this.userManagementStore.updateSecurityPolicy(this.securityPolicy)) {
        this.emitter.emit('show-toast', { toastType: 'error', toastText: this.$t('securityPolicyView.errorOnSecurityPolicyUpdate') });
        return;
      }
      await this.updateSecurityPolicyInfo();
      this.areFieldsEdited = false;
      this.emitter.emit('show-toast', { toastType: 'info', toastText: this.$t('securityPolicyView.securityPolicyUpdated') });
    },
  },
  async mounted() {
    await this.updateSecurityPolicyInfo();
  },
};
</script>
