import { createRouter, createWebHistory } from 'vue-router';
import TransitView from '@/Views/TransitView.vue';
import AuthView from '@/Views/AuthView.vue';
import UserView from '@/Views/UserView.vue';
import useTransitStore from '@/stores/TransitStore';
import userManagementStore from '@/stores/UserManagementStore';

const routes = [
  {
    path: '/',
    name: 'transit-viewer',
    component: TransitView,
  },
  {
    path: '/login',
    name: 'login',
    component: AuthView,
  },
  {
    path: '/user',
    name: 'user',
    component: UserView,
  },
];

const router = createRouter({
  mode: 'history',
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

let transitStore = null;

// all protected routes must be redirected to /login if the user is not logged in
router.beforeEach((to, from, next) => {
  const publicPages = ['/login'];

  if (transitStore === null) {
    transitStore = useTransitStore();
  }

  if (!publicPages.includes(to.path)
    && (!userManagementStore().isSignedIn()
      || userManagementStore().isUserReset()
      || userManagementStore().isUserExpired())) {
    next('/login');
  } else {
    next();
  }
});

export default router;
