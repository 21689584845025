<template>
    <div class="relative w-full bg-[#1e3c53] rounded-xl shadow px-3 mb-5 py-10 flex flex-col items-center gap-10">
        <div class="relative w-2/3">
          <p class="text-xl absolute top-[-0.6rem] left-2 bg-white px-6 border-3 shadow
          border-[#1e3c53] rounded text-gray-700 capitalize">{{ this.$t('usersTab.users') }}</p>
          <div class="bg-gray-100 mx-auto my-auto rounded-2xl flex flex-col
            justify-center items-center place-items-center place-content-center py-6 gap-3 px-4">
          <SearchBar
            :restoreFromSessionStorage="false"
            :placeholder="this.$t('usersTab.filterByUsername')"
            @update-fetch-params="onFilterUpdated" />
            <div class="w-full" v-for="user in this.filteredUsers" v-bind:key="user.id">
                <UserInfoViewCard
                  :currentUserInfo="user"
                  @users-updated="updateUsers" />
            </div>
            <UserInfoViewCard
              class="w-full"
              v-if="this.isAddingUser"
              @users-updated="updateUsers" />
            <AddSomethingButton v-if="this.userManagementStore.hasAllUsersWritePermission()"
            :buttonText="this.$t('usersTab.addNewUserButtonText')"
            @button-clicked="addNewUser"/>
          </div>
          <div class="absolute bottom-4 right-4 text-xs font-bold h-fit">{{ this.$t('facilityTab.mandatoryFields') }}</div>
        </div>
    </div>
</template>

<script>
import useTransitStore from '@/stores/TransitStore';
import useUserManagementStore from '@/stores/UserManagementStore';
import AddSomethingButton from '@/components/generic/AddSomethingButton.vue';
import UserInfoViewCard from '@/components/userTabComponents/UserInfoViewCard.vue';
import SearchBar from '@/components/SearchBar.vue';

export default {
  name: 'UsersTab',
  components: {
    AddSomethingButton,
    UserInfoViewCard,
    SearchBar,
  },
  data() {
    return {
      isAddingUser: false,
      filterValues: {
        searchValue: '',
      },
      filteredUsers: [],
      adminCount: 0,
    };
  },
  setup() {
    const transitStore = useTransitStore();
    const userManagementStore = useUserManagementStore();
    return {
      transitStore,
      userManagementStore,
    };
  },
  methods: {
    async updateUsers() {
      this.filteredUsers = await this.userManagementStore.fetchFilteredUsers(this.filterValues);
      this.isAddingUser = false;
    },
    addNewUser() {
      this.isAddingUser = true;
    },
    async onFilterUpdated(filterValue) {
      this.filterValues.searchValue = filterValue.searchValue;
      await this.updateUsers();
    },
  },
  async mounted() {
    await this.userManagementStore.fetchRoles();
    await this.updateUsers();
    this.emitter.on('new_user_added', this.updateUsers);
  },
  unmounted() {
    this.emitter.off('new_user_added');
  },
};
</script>
