<template>
  <ResetPassword
    :show="this.showResetPassword"
    class="absolute top-0 left-0"
    @change-password-cancelled="hideResetPasswordDialog"
    @password-changed="hideResetPasswordDialog"/>
  <div v-if="showAccountInfoId !== undefined" class="flex flex-col w-fit mt-20 items-start shadow-2xl
  bg-white border border-gray-200 rounded-lg hover:border-gray-400 justify-center pt-3 gap-2 px-3">
    <h1 class="text-xl self-center mb-2">{{ this.$t('accountInfo.accountInfoTitle') }}</h1>
    <Form class="space-y-3" action="#" v-slot="{ errors }"
         method="POST" @submit="submitForm"
          :validation-schema="schemaValidation">
        <div class="flex flex-col rounded-md mt-3 gap-3">
          <div>
            <label htmlFor="email">
              <span class="font-medium">{{ $t('accountInfo.emailAdress') }}</span>
              <Field :validateOnBlur="true"
                :validateOnChange="false" :validateOnInput="true"
                id="email" name="email" v-model="this.accountInfo.email"
                  type="email" required autocomplete="email"
                  :class="fieldStyle" :placeholder="$t('accountInfo.emailAdress')" />
            </label>
            <ErrorMessage name="email" class="invalid-feedback" />
          </div>
          <div>
            <label htmlFor="given-name">
              <span class="font-medium">{{ $t('accountInfo.name') }}</span>
              <Field :validateOnBlur="true"
                :validateOnChange="false" :validateOnInput="true"
                id="given-name" name="given-name" v-model="this.accountInfo.name"
                  type="text" required autocomplete="given-name"
                  :class="fieldStyle" :placeholder="$t('accountInfo.name')" />
            </label>
            <ErrorMessage name="given-name" class="invalid-feedback" />
          </div>
          <div>
            <label htmlFor="family-name">
              <span class="font-medium">{{ $t('accountInfo.surname') }}</span>
              <Field :validateOnBlur="true"
                :validateOnChange="false" :validateOnInput="true"
                id="family-name" name="family-name" v-model="this.accountInfo.surname"
                  type="text" required autocomplete="family-name"
                  :class="fieldStyle" :placeholder="$t('accountInfo.surname')" />
            </label>
            <ErrorMessage name="family-name" class="invalid-feedback" />
          </div>
        </div>
        <p v-show="this.accountInfo.lastLoginAt"
          class="text-[0.625rem] italic text-gray-600 justify-center text-center w-full">
          <span class="font-semibold">
            {{ this.$t('accountInfo.lastLoginAt') }}:
          </span>
          <span>
             {{ getLocalDateTimeFromTimestamp(this.accountInfo.lastLoginAt) }}
          </span>
        </p>
        <div>
          <hr class="border-t-1 border-dashed border-gray-300 mt-4 mb-2"/>
          <div class="flex gap-6 justify-between">
            <button class="mt-2 py-2 text-gray-600 hover:font-bold
            underline underline-offset-4 hover:decoration-4 hover:text-rose-950"
            @click="goBack()">
              {{ $t('accountInfo.goBack') }}
            </button>
            <button type="submit"
              class="mt-2 px-3 py-1 bg-sky-500 text-white rounded
                hover:bg-sky-700 transition-colors duration-300"
                :disabled="errors.email || errors.name">
                {{ $t('accountInfo.modify') }}
            </button>
          </div>
        </div>
    </Form>
    <button @click="showResetPasswordDialog" class="flex self-center
      text-xs font-medium text-gray-600 object-contain underline underline-offset-4
      hover:text-red-600 hover:font-semibold focus:outline-none disabled:bg-[#83878a] my-3">
      {{ this.$t('resetPassword.changeCurrentTitle') }}
    </button>
  </div>
</template>
<script>
import {
  Form, Field, ErrorMessage,
} from 'vee-validate';
import * as yup from 'yup';
import { getLocalDateTimeFromTimestamp } from '@/utils/date';
import useTransitStore from '@/stores/TransitStore';
import useUserManagementStore from '@/stores/UserManagementStore';
import ResetPassword from '@/components/ResetPassword.vue';

export default {
  name: 'AccountInfo',
  components: {
    Form,
    Field,
    ErrorMessage,
    ResetPassword,
  },
  setup() {
    const transitStore = useTransitStore();
    const userManagementStore = useUserManagementStore();
    return {
      transitStore,
      userManagementStore,
      getLocalDateTimeFromTimestamp,
    };
  },
  data() {
    const fieldStyle = 'w-full p-1 hover:bg-gray-100 border border-gray-300'
    + 'placeholder-gray-500 text-gray-900 rounded-md focus:outline-none'
    + 'focus:ring-sky-500 focus:border-sky-500 focus:z-10 sm:text-sm focus:shadow-inner';
    return {
      accountInfo: {},
      fieldStyle,
      schemaValidation: yup.object({
        'given-name': yup.string().required(this.$t('accountInfo.enterValidUsername')).min(1),
        email: yup.string().required().email(this.$t('accountInfo.enterValidEmail')),
      }),
      showResetPassword: false,
    };
  },
  mounted() {
    this.accountInfo = { ...this.userManagementStore.getAccountInfo() };
    this.userManagementStore.startRefreshTokenPeriodicJob();
  },
  unmounted() {
    this.userManagementStore.stopRefreshTokenPeriodicJob();
  },
  methods: {
    async submitForm() {
      try {
        const response = await this.userManagementStore.handleUserData(
          {
            email: this.accountInfo.email,
            name: this.accountInfo.name,
            surname: this.accountInfo.surname,
          },
        );
        if (response !== undefined && response.id !== undefined) {
          this.emitter.emit('show-toast', { toastType: 'info', toastText: this.$t('accountInfo.userDataUpdated') });
          this.emitter.emit('update-user-account-info');
        } else throw new Error(`No response or id is undefined: ${response}`);
      } catch (error) {
        this.emitter.emit('show-toast', {
          toastType: 'error',
          toastText: this.$t('accountInfo.errorOnUpdate'),
        });
        console.error(error);
      }
      return 1;
    },
    goBack() {
      this.$router.push({ path: '/' });
    },
    showResetPasswordDialog() {
      this.showResetPassword = true;
    },
    hideResetPasswordDialog() {
      this.showResetPassword = false;
    },
  },
  computed: {
    showAccountInfoId() {
      return this.accountInfo.id;
    },
  },
};
</script>
