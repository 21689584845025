<template>
  <div>
    <div class="relative">
      <div class="flex items-center" v-show="!(this.preselectedOptions?.length === this.comboOptions.length)">
        <button
          ref="titleButton"
          :class="[this.buttonFullRounded ? 'rounded-full' : 'rounded-md',
            'items-center border bg-gray-50 hover:bg-gray-200 relative p-1',
            'transition-colors duration-200 ease-in-out transform hover:shadow-lg hover:scale-105']"
          @click="toggleDropdown">
          <i>
            <img class="min-w-max h-6" :src="useGetIcon(this.buttonIcon)" alt="Dropdown button icon"/>
          </i>
        </button>
        <div ref="dropdown"
          v-show="!dropdownHidden"
          :class="[this.showDropdownOnRightSide ? '-left-5': '-right-5',
            'absolute top-9 rounded-md shadow-lg bg-white cursor-pointer z-40']">
          <div v-for="optionValue in this.getOptions()" :key="optionValue.id">
            <button
              :disabled="optionValue.disabled"
              :title="(optionValue.disabled?'This user cannot be deleted' : '')"
              class="flex items-center text-left text-gray-700
                    hover:bg-gray-100 hover:rounded-md hover:text-gray-900
                    w-full p-2 overflow-hidden truncate hover:overflow-auto
                    disabled:text-gray-300 disabled:bg-white transform hover:scale-105 disabled:scale-100"
              @click="optionToggled(optionValue.id)">
              <i class="min-w-max h-5" v-if="this.buttonItemIcon || optionValue.iconName">
                <img class="min-w-max h-5"
                  :src="useGetIcon(optionValue.iconName ? getProperIconNameIfDisabled(optionValue) : this.buttonItemIcon)"
                  alt="Dropdown item button icon" />
              </i>
              <div :class="[(this.buttonItemIcon || optionValue.iconName)? 'pl-2' : '',
                (!optionValue.disabled? optionValue.buttonItemTextStyle : '')]">
                {{ getAliasOrNameFromObject(optionValue) }}
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { getIcon as useGetIcon } from '@/utils/icons';
import getAliasOrNameFromObject from '@/utils/objects';

function getProperIconNameIfDisabled(optionValue) {
  if (optionValue?.disabled && optionValue.disabledIconName) {
    return optionValue.disabledIconName;
  }
  return optionValue.iconName;
}

export default {
  name: 'IconDropdownMenu',
  props: {
    comboOptions: {
      type: Object,
      required: true,
      default() {
        return {
          iconName: '',
          disabledIconName: '',
          name: '',
          buttonItemTextStyle: '',
          disabled: false,
          hidden: false,
        };
      },
    },
    preselectedOptions: {
      type: Array,
      required: false,
    },
    notShowIfSelected: {
      type: Boolean,
      required: false,
      default: false,
    },
    showDropdownOnRightSide: {
      type: Boolean,
      required: false,
      default: false,
    },
    buttonFullRounded: {
      type: Boolean,
      required: false,
      default: true,
    },
    buttonIcon: {
      type: String,
      required: true,
    },
    buttonItemIcon: {
      type: String,
      required: false,
    },
  },
  setup() {
    return {
      useGetIcon,
      getProperIconNameIfDisabled,
      getAliasOrNameFromObject,
    };
  },
  data() {
    return {
      dropdownHidden: true,
      selectedOptionIds: [],
    };
  },
  methods: {
    getOptions() {
      return this.comboOptions.filter((option) => !option.hidden && this.showIfNotSelected(option.id));
    },
    alreadySelected(optionId) {
      return this.preselectedOptions.some((selectedOption) => selectedOption.id === optionId);
    },
    showIfNotSelected(optionId) {
      if (this.notShowIfSelected) {
        return !(this.preselectedOptions.some((selectedOption) => selectedOption.id === optionId));
      }
      return true;
    },
    toggleDropdown() {
      this.emitter.emit('close-other-combos', this);
      this.dropdownHidden = !this.dropdownHidden;
      if (!this.dropdownHidden) {
        this.selectedOptionIds = [];
        this.preselectedOptions?.forEach((selectedOption) => this.selectedOptionIds.push(selectedOption.id));
      }
    },
    closeDropdown() {
      this.dropdownHidden = true;
    },
    optionToggled(optionId) {
      this.selectedOptionIds.push(optionId);
      this.$emit('update:selection-changed', this.selectedOptionIds);
      this.dropdownHidden = true;
    },
  },
  mounted() {
    this.emitter.on('close-other-combos', (emitterCombo) => {
      if (emitterCombo !== this) {
        this.dropdownHidden = true;
      }
    });
  },
  unmounted() {
    this.emitter.off('close-other-combos');
  },
};
</script>
