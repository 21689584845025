<template>
  <div class="flex items-center space-x-4 ml-4 mt-4 relative">
    <img
      :src= this.appLogo
      class="h-12 w-auto"
      alt="AllRead Logo"/>
    <div class="absolute top-10 right-16 flex" v-if="isSignedIn">
      <UserMenu @accountInfoFromUserMenu="accountInfoReceived" />
      <a class="group flex text-sm hover:text-gray-600 rounded-xl z-20
        bg-white hover:bg-sky-200 border border-gray-300 hover:border-white
        transition-colors duration-300 text-center my-1 w-fit py-2 px-2 justify-center"
        target="_blank"
        href="https://allread.atlassian.net/servicedesk/customer/portals"
        :title="this.$t('userMenu.supportTitle')">
        <img :src="getIcon('supportIcon')"
          class="w-5 h-5 group-hover:mr-1"
          :title="this.$t('userMenu.supportTitle')"
          alt="support icon"/>
      </a>
    </div>
  </div>
  <div class="absolute w-full">
  <ToastComponent ref="userAccountToast" class="flex mx-auto relative -top-3 z-10 justify-center items-center"/>
  </div>
  <div class="absolute top-0 left-0 flex items-start justify-center w-full h-full">
    <AccountInfo />
  </div>
</template>

<script>
import ToastComponent from '@/components/generic/ToastComponent.vue';
import AccountInfo from '@/components/AccountInfo.vue';
import useTransitStore from '@/stores/TransitStore';
import useUserManagementStore from '@/stores/UserManagementStore';
import UserMenu from '@/components/UserMenu.vue';
import { thereIsClientLogo } from '@/../TransitViewerConfig';
import { getIcon } from '@/utils/icons';

export default {
  name: 'UserView',
  setup() {
    const transitStore = useTransitStore();

    return {
      transitStore,
      getIcon,
    };
  },
  data() {
    return {
      appLogo: getIcon('applicationLogo'),
    };
  },
  components: {
    AccountInfo,
    UserMenu,
    ToastComponent,
  },
  methods: {
    accountInfoReceived() {
      this.$router.push({ path: '/user' });
    },
  },
  computed: {
    isSignedIn() {
      return useUserManagementStore().isSignedIn();
    },
  },
  async mounted() {
    if (thereIsClientLogo()) {
      const clientLogo = await this.transitStore.fetchClientLogo();
      if (clientLogo) {
        this.appLogo = clientLogo;
      }
    }
    this.emitter.on('show-toast', (toastInfo) => {
      this.$refs.userAccountToast.openToast(toastInfo);
    });
  },
  unmounted() {
    this.emitter.off('show-toast');
  },
};
</script>
