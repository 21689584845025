<template>
  <div class="relative w-full bg-[#1e3c53] rounded-xl shadow px-3 mb-5 py-10 flex flex-col items-center gap-10">
    <div class="relative w-2/3">
      <p class="text-xl absolute top-[-0.6rem] left-2 bg-white px-6 border-3 shadow
      border-[#1e3c53] rounded text-gray-700 capitalize">{{ this.$t('securityTab.securityPolicy') }}</p>
      <div class="bg-gray-100 mx-auto my-auto rounded-2xl flex flex-col
        justify-center items-center place-items-center place-content-center py-6 gap-3 px-4">
        <SecurityPolicyView />
      </div>
    </div>
    <div class="relative w-2/3">
      <p class="text-xl absolute top-[-0.6rem] left-2 bg-white px-6 border-3 shadow
      border-[#1e3c53] rounded text-gray-700 capitalize">{{ this.$t('securityTab.securityPolicyMessage') }}</p>
      <div class="bg-gray-100 mx-auto my-auto rounded-2xl flex flex-col
        justify-center items-center place-items-center place-content-center py-6 gap-3 px-4">
        <SecurityPolicyMessageView />
      </div>
    </div>
  </div>
</template>

<script>
import SecurityPolicyView from '@/components/securityTabComponents/SecurityPolicyView.vue';
import SecurityPolicyMessageView from '@/components/securityTabComponents/SecurityPolicyMessageView.vue';

export default {
  name: 'SecurityTab',
  components: {
    SecurityPolicyView,
    SecurityPolicyMessageView,
  },
};
</script>
