<template>
  <div dir="ltr">
    <div class="bg-white rounded-2xl px-5 py-3 w-full shadow relative">
      <i class="min-w-max h-20 absolute top-8 left-16 opacity-95 p-1
          border border-gray-100 rounded shadow-xl place-content-center">
        <img class="w-16 h-16" :src="getIcon('userIcon')" alt="generic user icon" />
        <img v-show="this.currentUserInfo?.is_blocked"
          :src="getIcon('blockedWarnIcon')"
          class="w-8 h-8 absolute -top-3 -left-5"
          alt="user blocked icon"
          :title="this.$t('userInfoViewCard.blockedUser')">
      </i>
      <IconDropdownMenu
        v-if="this.currentUserInfo && this.userManagementStore.hasAllUsersWritePermission()"
        class="absolute top-2 end-2 min-w-max h-14"
        :comboOptions="this.dropdownOptions"
        :notShowIfSelected=false
        buttonIcon="kebabIcon"
        @update:selection-changed="doDropdownAction"/>
      <div class="flex flex-col items-centered justify-center py-3">
        <div class="grid grid-cols-[49%_2%_49%] gap-1">

          <p class="place-self-end p-1">{{ this.$t('userInfoViewCard.userName') }}:</p>
          <p class="text-red-800 place-self-center self-center text-center justify-center max-w-2">*</p>
          <EditableField
            :fieldId="`userName${this.currentUserInfo?.id}`"
            :fieldValidator="validateName"
            :value="this.userInfo?.name"
            :nonEditable="!this.userManagementStore.hasAllUsersWritePermission()"
            :placeholder="this.$t('userInfoViewCard.userNamePlaceholder')"/>

          <p class="place-self-end p-1 self-center">{{ this.$t('userInfoViewCard.surname') }}:</p>
          <span></span>
          <EditableField
            :fieldId="`userSurname${this.currentUserInfo?.id}`"
            :fieldValidator="setUserSurname"
            :value="this.userInfo?.surname"
            :nonEditable="!this.userManagementStore.hasAllUsersWritePermission()"
            :placeholder="this.$t('userInfoViewCard.surnamePlaceholder')"/>

          <p class="place-self-end p-1 self-center">{{ this.$t('userInfoViewCard.emailAdress') }}:</p>
          <p class="text-red-800 place-self-center self-center text-center justify-center max-w-2">*</p>
          <EditableField
            :fieldId="`userEmail${this.currentUserInfo?.id}`"
            :fieldValidator="validateEmail"
            :value="this.userInfo?.email"
            :nonEditable="!this.userManagementStore.hasAllUsersWritePermission()"
            :placeholder="this.$t('userInfoViewCard.emailPlaceholder')"/>

          <p class="place-self-end p-1 self-center">{{ this.$t('userInfoViewCard.userRole') }}:</p>
          <p class="text-red-800 place-self-center self-center text-center justify-center max-w-2">*</p>
          <UserRoleSelector
            ref="userRoleSelector"
            class="place-self-start"
            uniqueComboName="userInfoViewCardRoleCombo"
            :nonEditable="this.currentUserInfo && (!this.userManagementStore.hasAllUsersWritePermission()
            || !this.currentUserInfo?.allow_destructive_operations)"
            @update:roleSelectorChanged="setUserRole"
            :preselectedOptionId="this.userInfo?.role?.id"/>

        </div>
        <p v-show="this.currentUserInfo?.current_login_at"
          class="text-[0.625rem] italic text-gray-600 justify-end text-left w-full">
          <span class="font-semibold">
            {{ this.$t('userInfoViewCard.lastLoginAt') }}:
          </span>
          <span>
             {{ getLocalDateTimeFromTimestamp(this.currentUserInfo?.current_login_at) }}
          </span>
        </p>
        <hr v-if="this.errorMessage" class="border-t-1 border-dashed border-gray-300 mt-4 mb-4"/>
        <pre v-if="this.errorMessage" class="mt-2 text-red-700 text-sm">{{ this.errorMessage }}</pre>
      </div>
      <hr v-show="this.areFieldsEdited || !this.currentUserInfo" class="border-t-1 border-dashed border-gray-300 mt-2 mb-1"/>
      <div class="flex gap-6 justify-center" v-show="this.areFieldsEdited || !this.currentUserInfo">
        <button @click="cancelUserAddOrEdit"
          class="mt-2 px-4 py-2 text-gray-600 hover:font-bold underline underline-offset-4 hover:decoration-4 hover:text-rose-950">
          {{ $t('locationInfoViewCard.cancelChangesButton') }}
        </button>
        <button @click="submitUser"
          class="mt-2 px-3 py-1 bg-sky-500 text-white rounded
            hover:bg-sky-700 transition-colors duration-300 disabled:bg-gray-300"
            :disabled="!this.submitButtonEnabled">
            {{ $t('facilityInfoViewCard.applyChangesButton') }}
        </button>
      </div>
      <ModalDialog
        ref="userInfoViewCardModal"
        @lock-user="requestLockUser"
        @change-password="requestSingleUsePassword"
        @delete-user="deleteUser"
        @new_user_added="postAddNewUser"/>
    </div>
  </div>
</template>

<script>
import useTransitStore from '@/stores/TransitStore';
import useUserManagementStore from '@/stores/UserManagementStore';
import EditableField from '@/components/generic/EditableField.vue';
import IconDropdownMenu from '@/components/generic/IconDropdownMenu.vue';
import UserRoleSelector from '@/components/userTabComponents/UserRoleSelector.vue';
import ModalDialog from '@/components/generic/ModalDialog.vue';
import { getIcon } from '@/utils/icons';
import { getLocalDateTimeFromTimestamp } from '@/utils/date';
import { httpStatus } from '@/utils/http_status';

export default {
  name: 'UserInfoViewCard',
  props: {
    currentUserInfo: {
      type: Object,
      required: false,
    },
  },
  components: {
    EditableField,
    UserRoleSelector,
    ModalDialog,
    IconDropdownMenu,
  },
  data() {
    return {
      errorMessages: {
        name: '',
        contactEmail: '',
      },
      errorMessage: '',
      submitButtonEnabled: false,
      areFieldsEdited: false,
      userInfo: {},
      dropdownOptions: [
        {
          id: 'lock-user',
          name: this.$t('userInfoViewCard.lockUserMenuText'),
          iconName: 'lockUserIcon',
          hidden: false,
        },
        {
          id: 'unlock-user',
          name: this.$t('userInfoViewCard.unlockUserMenuText'),
          iconName: 'unlockUserIcon',
          hidden: false,
        },
        {
          id: 'reset-user-password',
          name: this.$t('userInfoViewCard.resetUserPasswordMenuText'),
          iconName: 'passwordResetIcon',
        },
        {
          id: 'delete-user',
          name: this.$t('userInfoViewCard.deleteUserMenuText'),
          iconName: 'deleteIcon',
          disabledIconName: 'deleteIconDisabled',
          buttonItemTextStyle: 'text-red-800',
          disabled: !this.currentUserInfo?.allow_destructive_operations,
        },
      ],
    };
  },
  setup() {
    const userManagementStore = useUserManagementStore();
    const transitStore = useTransitStore();
    return {
      transitStore,
      userManagementStore,
      getLocalDateTimeFromTimestamp,
      getIcon,
    };
  },
  methods: {
    validateName(editedName) {
      this.errorMessages.name = (editedName?.length < 3) ? this.$t('userInfoViewCard.nameMinimumLenght') : '';
      this.userInfo.name = editedName;
      this.joinValidations();
    },
    validateEmail(editedEmail) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      this.errorMessages.contactEmail = (editedEmail?.length > 50
                                      || !emailPattern.test(editedEmail)) ? this.$t('userInfoViewCard.enterValidEmail') : '';
      this.userInfo.email = editedEmail;
      this.joinValidations();
    },
    setUserRole(selectedRoleId) {
      this.areFieldsEdited = true;
      this.userInfo.role = selectedRoleId;
      this.joinValidations();
    },
    setUserSurname(surnameEdited) {
      this.userInfo.surname = surnameEdited;
      this.joinValidations();
    },
    joinValidations() {
      this.errorMessage = Object.values(this.errorMessages).filter(Boolean).join('\n');
      this.areFieldsEdited = (JSON.stringify(this.currentUserInfo) !== JSON.stringify(this.userInfo));
      this.submitButtonEnabled = !this.errorMessage
      && this.areFieldsEdited
      && this.userInfo.name
      && this.userInfo.email
      && this.userInfo.role;
    },
    async addNewUser() {
      const response = await this.userManagementStore.addUser(this.userInfo, [this.userInfo.role]);
      if (!response.ok) {
        if (response.status === httpStatus.CONFLICT) {
          throw new Error('user conflict');
        }
        throw new Error('error creating user');
      }
      const userResponse = response.content;
      this.userInfo.id = userResponse.id;
      this.userInfo.allow_destructive_operations = userResponse.allow_destructive_operations;
      navigator.clipboard.writeText(userResponse.single_use_password);
      const modalInfo = {
        modalTitle: this.$t('userInfoViewCard.newUserCreatedModalTitle'),
        modalText: this.$t('userInfoViewCard.userAddedSuccessfully', { username: this.userInfo.name, what: this.$t('userInfoViewCard.added') }),
        modalQuestion: this.$t('userInfoViewCard.modalUserPasswordCopiedQuestion'),
        showCancelButton: false,
        signalOnAccept: 'new_user_added',
      };
      this.$refs.userInfoViewCardModal.openModal(modalInfo);
    },
    async postAddNewUser() {
      this.$emit('users-updated');
      this.emitter.emit('new_user_added');
    },
    async editUser() {
      const response = await this.userManagementStore.editUser(this.userInfo);
      if (!response.ok) {
        throw new Error('error editing user');
      }
      if (this.currentUserInfo.role?.id !== this.userInfo.role.id) {
        if (!await this.userManagementStore.putUserRole(this.userInfo.id, [this.userInfo.role])) {
          throw new Error('error setting user role');
        }
      }
      this.emitter.emit('show-toast', {
        toastType: 'info',
        toastText: this.$t('userInfoViewCard.userAddedSuccessfully', { username: this.userInfo.name, what: this.$t('userInfoViewCard.edited') }),
      });
      this.emitter.emit('new_user_added');
    },
    async submitUser() {
      this.resetStateFlags();
      const isEdition = Number.isInteger(this.userInfo?.id);
      try {
        if (isEdition) {
          await this.editUser();
        } else {
          await this.addNewUser();
        }
      } catch (error) {
        console.error('Error adding user', error);
        this.emitter.emit('show-toast', {
          toastType: 'error',
          toastText: this.$t('userInfoViewCard.errorAddingUser', { username: this.userInfo.name }),
        });
        this.cancelUserAddOrEdit();
      }
    },
    cancelUserAddOrEdit() {
      this.setUserInfoFromCurrent();
      this.errorMessage = '';
      this.resetStateFlags();
      this.$emit('users-updated');
    },
    resetStateFlags() {
      this.areFieldsEdited = false;
      this.submitButtonEnabled = false;
    },
    setUserInfoFromCurrent() {
      if (!this.currentUserInfo) {
        this.userInfo = {};
        return;
      }
      this.userInfo = { ...this.currentUserInfo };
      this.$refs.userRoleSelector.setPreselectedOptionId(this.userInfo.role.id);
      this.resetStateFlags();
    },
    showLockUserWarning() {
      const modalInfo = {
        modalTitle: this.$t('userInfoViewCard.modalLockUserTitle'),
        modalText: this.$t('userInfoViewCard.modalLockUserText', { who: this.currentUserInfo.name }),
        modalQuestion: this.$t('userInfoViewCard.modalUserTabQuestion'),
        showCancelButton: true,
        signalOnAccept: 'lock-user',
      };
      this.$refs.userInfoViewCardModal.openModal(modalInfo);
    },
    async requestLockUser() {
      if (await this.userManagementStore.lockUser(this.currentUserInfo.id)) {
        this.emitter.emit('show-toast', {
          toastType: 'info',
          toastText: this.$t('userInfoViewCard.userLockSuccessfully', { who: this.currentUserInfo.name }),
        });
        this.$emit('users-updated');
      } else {
        this.emitter.emit('show-toast', {
          toastType: 'error',
          toastText: this.$t('userInfoViewCard.userLockFailed', { who: this.currentUserInfo.name }),
        });
      }
    },
    async requestUnlockUser() {
      if (await this.userManagementStore.unlockUser(this.currentUserInfo.id)) {
        this.emitter.emit('show-toast', {
          toastType: 'info',
          toastText: this.$t('userInfoViewCard.userUnlockSuccessfully', { who: this.currentUserInfo.name }),
        });
        this.$emit('users-updated');
      } else {
        this.emitter.emit('show-toast', {
          toastType: 'error',
          toastText: this.$t('userInfoViewCard.userUnlockFailed', { who: this.currentUserInfo.name }),
        });
      }
    },
    showChangePasswordWarning() {
      const modalInfo = {
        modalTitle: this.$t('userInfoViewCard.modalResetUserPasswordTitle'),
        modalText: this.$t('userInfoViewCard.modalResetUserPasswordText'),
        modalQuestion: this.$t('userInfoViewCard.modalUserTabQuestion'),
        showCancelButton: true,
        signalOnAccept: 'change-password',
      };
      this.$refs.userInfoViewCardModal.openModal(modalInfo);
    },
    async requestSingleUsePassword() {
      const singleUsePassword = await this.userManagementStore.requestSingleUsePassword(this.userInfo.id);
      if (singleUsePassword) {
        navigator.clipboard.writeText(singleUsePassword);
        const modalInfo = {
          modalTitle: this.$t('userInfoViewCard.modalResetUserPasswordTitle'),
          modalText: this.$t('userInfoViewCard.modalUserPasswordCopiedText'),
          modalQuestion: this.$t('userInfoViewCard.modalUserPasswordCopiedQuestion'),
          showCancelButton: false,
        };
        this.$refs.userInfoViewCardModal.openModal(modalInfo);
      } else {
        this.emitter.emit('show-toast', { toastType: 'error', toastText: this.$t('userInfoViewCard.modalResetUserPasswordFailed') });
      }
    },
    showDeleteUserWarning() {
      const modalInfo = {
        modalTitle: this.$t('userInfoViewCard.modalDeleteUserTitle'),
        modalText: this.$t('userInfoViewCard.modalDeleteUserText', { who: this.currentUserInfo.name }),
        modalQuestion: this.$t('userInfoViewCard.modalUserTabQuestion'),
        showCancelButton: true,
        signalOnAccept: 'delete-user',
      };
      this.$refs.userInfoViewCardModal.openModal(modalInfo);
    },
    async deleteUser() {
      if (await this.userManagementStore.deleteUser(this.currentUserInfo.id)) {
        this.emitter.emit('show-toast', {
          toastType: 'info',
          toastText: this.$t('userInfoViewCard.userDeletedSuccessfully', { who: this.currentUserInfo.name }),
        });
        this.$emit('users-updated');
      } else {
        this.emitter.emit('show-toast', {
          toastType: 'error',
          toastText: this.$t('userInfoViewCard.userDeleteFailed', { who: this.currentUserInfo.name }),
        });
      }
    },
    doDropdownAction(selectedOptions) {
      switch (selectedOptions[0]) {
        case 'lock-user':
          this.showLockUserWarning();
          break;
        case 'unlock-user':
          this.requestUnlockUser();
          break;
        case 'reset-user-password':
          this.showChangePasswordWarning();
          break;
        case 'delete-user':
          this.showDeleteUserWarning();
          break;
        default:
          break;
      }
    },
    updateUserDropdownOptions() {
      const deleteOption = this.dropdownOptions.find((option) => option.id === 'delete-user');
      deleteOption.disabled = !this.currentUserInfo?.allow_destructive_operations;
      const lockUserOption = this.dropdownOptions.find((option) => option.id === 'lock-user');
      lockUserOption.hidden = this.currentUserInfo?.is_blocked;
      lockUserOption.disabled = !this.currentUserInfo?.allow_destructive_operations;
      const unlockUserOption = this.dropdownOptions.find((option) => option.id === 'unlock-user');
      unlockUserOption.hidden = !this.currentUserInfo?.is_blocked;
      unlockUserOption.disabled = !this.currentUserInfo?.allow_destructive_operations;
    },
  },
  mounted() {
    this.setUserInfoFromCurrent();
    this.updateUserDropdownOptions();
  },
  watch: {
    currentUserInfo: 'updateUserDropdownOptions',
  },
};
</script>
