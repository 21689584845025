export function apiUrl() {
  return 'https://pre.allread.ai/api';
}

export function transitStorageWs() {
  return 'wss://pre.allread.ai/api/ws-comms';
}

export function authMethodsIndexes() {
  return '1';
}

export function thereIsClientLogo() {
  return '';
}

export function viewerLanguage() {
  return 'en';
}
