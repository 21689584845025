export const STATISTICAL_TYPE = {
  transits: {
    name: 'statsLookup.transits',
    iconName: 'transitIcon',
    hidden: false,
  },
  BIC: {
    name: 'statsLookup.container',
    iconName: 'containerIcon',
    hidden: false,
  },
  ISO: {
    name: 'statsLookup.container_iso',
    iconName: 'containerIcon',
    hidden: false,
  },
  adr: {
    name: 'statsLookup.adr',
    iconName: 'adrIcon',
    hidden: false,
  },
  country: {
    name: 'statsLookup.country',
    iconName: 'flagIcon',
    hidden: false,
  },
  'license plate': {
    name: 'statsLookup.license_plate',
    iconName: 'licensePlateIcon',
    hidden: false,
  },
  'trailer license plate': {
    name: 'statsLookup.trailer_license_plate',
    iconName: 'trailerLicensePlateIcon',
    hidden: false,
  },
  transit_direction: {
    name: 'statsLookup.transit_direction',
    iconName: 'transitDirectionIcon',
    hidden: true,
  },
  universalcontainer: {
    name: 'statsLookup.container',
    iconName: 'containerIcon',
    hidden: false,
  },
  universallicenseplate: {
    name: 'statsLookup.license_plate',
    iconName: 'licensePlateIcon',
    hidden: false,
  },
  wagon: {
    name: 'statsLookup.wagon',
    iconName: 'wagonIcon',
    hidden: false,
  },
  universalwagon: {
    name: 'statsLookup.wagon',
    iconName: 'wagonIcon',
    hidden: false,
  },
  locomotive: {
    name: 'statsLookup.locomotive',
    iconName: 'locomotiveIcon',
    hidden: false,
  },
  vehicle: {
    name: 'statsLookup.vehicle_type',
    iconName: 'vehicleClassificationIcon',
    hidden: false,
  },
  vehicleclassification: {
    name: 'statsLookup.vehicle_type',
    iconName: 'vehicleClassificationIcon',
    hidden: false,
  },
  vehicledetection: {
    name: 'statsLookup.vehicle_type',
    iconName: 'vehicleClassificationIcon',
    hidden: false,
  },
  vehicleinformation: {
    name: 'statsLookup.vehicle_information',
    iconName: 'brandIcon',
    hidden: false,
  },
  'vehicle information': {
    name: 'statsLookup.vehicle_information',
    iconName: 'brandIcon',
    hidden: false,
  },
  vehicleinformation_brand: {
    name: 'statsLookup.vehicle_brand',
    iconName: 'brandIcon',
    hidden: false,
  },
  vehicleinformation_color: {
    name: 'statsLookup.vehicle_color',
    iconName: 'brandIcon',
    hidden: false,
  },
  vehicleinformation_model: {
    name: 'statsLookup.vehicle_model',
    iconName: 'brandIcon',
    hidden: false,
  },
  containertare: {
    name: 'statsLookup.weight_information',
    iconName: 'containerTareIcon',
    hidden: false,
  },
  max_gross_weight: {
    name: 'statsLookup.max_gross_weight',
    iconName: 'containerTareIcon',
    hidden: false,
  },
  max_net_weight: {
    name: 'statsLookup.max_net_weight',
    iconName: 'containerTareIcon',
    hidden: false,
  },
  tare: {
    name: 'statsLookup.tare',
    iconName: 'containerTareIcon',
    hidden: false,
  },
  sealreader: {
    name: 'statsLookup.container_seal',
    iconName: 'sealDetectorIcon',
    hidden: false,
  },
  sealdetector: {
    name: 'statsLookup.container_seal',
    iconName: 'sealDetectorIcon',
    hidden: false,
  },
  video_frame_position: {
    name: 'statsLookup.video_frame_position',
    iconName: '',
    hidden: true,
  },
  video_file_name: {
    name: 'statsLookup.video_file_name',
    iconName: '',
    hidden: true,
  },
  wagonseparation: {
    name: 'statsLookup.wagonseparation',
    iconName: '',
    hidden: true,
  },
};

export function getStatisticalTypeName(statisticalType) {
  try {
    return STATISTICAL_TYPE[statisticalType].name;
  } catch (error) {
    console.error('Key', statisticalType, 'not found');
    return statisticalType.replaceAll('_', ' ');
  }
}

export function isStatisticalTypeHidden(statisticalType) {
  try {
    return STATISTICAL_TYPE[statisticalType].hidden;
  } catch (error) {
    console.error('Key', statisticalType, 'not found');
    return true;
  }
}

export function getStatisticalType(statisticalTypeName) {
  let retKey = statisticalTypeName;
  Object.entries(STATISTICAL_TYPE).every(([key, val]) => {
    if (val.name === statisticalTypeName) {
      retKey = key;
      return false;
    }
    return true;
  });
  return retKey;
}

export function getStatisticalTypeIconName(statisticalType) {
  try {
    return STATISTICAL_TYPE[statisticalType].iconName;
  } catch (error) {
    console.error('Key', statisticalType, 'not found');
    return '';
  }
}
