<template>
  <div v-if=this.isOpen class="fixed top-0 left-0 w-full h-full z-40 bg-black bg-opacity-30 items-center flex">
    <div v-if=this.isOpen class="rounded-2xl shadow-2xl bg-white w-fit max-w-xl h-fit mx-auto px-4
    flex flex-col justify-between align-center py-4 gap-4">
      <p class="text-xl text-center justify-start my-4 underline underline-offset-8">
        {{ this.$t('securityPolicyMessageModal.acceptPolicyTitle') }}
      </p>
      <div class="flex flex-col">
        <p class="bg-gray-100 max-h-96 overflow-auto p-3 border border-gray-200 rounded-lg">
          {{ this.getSecurityPolicyText() }}
        </p>
        <p class="font-bold pt-6">
          {{ this.$t('securityPolicyMessageModal.acceptPolicyQuestion') }}
        </p>
        <a class="text-xs font-mono underline underline-offset-4 text-sky-600 pb-2 pt-6 w-fit
          hover:text-sky-900 hover:font-semibold"
          target="_blank"
          v-show="this.securityPolicyMessage.policy_link"
          :href="this.securityPolicyMessage.policy_link">{{ this.$t('securityPolicyMessageModal.policyLink') }}</a>
      </div>
      <hr class="border-t-1 border-dashed border-gray-300 mt-2 mb-1"/>
      <div class="flex mx-4 mt-2 justify-between">
        <button @click="cancelAndClose"
          class="px-4 py-2 text-gray-600 hover:font-bold underline underline-offset-4 hover:decoration-4 hover:text-rose-950">
          {{ $t('facilityInfoViewCard.cancel') }}
        </button>
        <button @click="acceptAndClose"
          class="px-3 py-1 bg-sky-500 text-white rounded
            hover:bg-sky-700 transition-colors duration-300 disabled:bg-gray-300">
            {{ this.$t('securityPolicyMessageModal.acceptPolicyButton') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import useUserManagementStore from '@/stores/UserManagementStore';

export default {
  name: 'SecurityPolicyMessageModal',
  components: {
  },
  setup() {
    const userManagementStore = useUserManagementStore();
    return {
      userManagementStore,
    };
  },
  data() {
    return {
      isOpen: false,
      securityPolicyMessage: {},
    };
  },
  methods: {
    async openModal() {
      this.securityPolicyMessage = await this.userManagementStore.fetchSecurityPolicyMessage();
      this.isOpen = true;
    },
    closeModal() {
      this.isOpen = false;
    },
    cancelAndClose() {
      this.$emit('deny-policy');
      this.closeModal();
    },
    acceptAndClose() {
      this.$emit('accept-policy');
      this.closeModal();
    },
    getSecurityPolicyText() {
      if (!this.securityPolicyMessage.policy_message) {
        return this.$t('securityPolicyMessageModal.acceptPolicyText');
      }
      return this.securityPolicyMessage.policy_message;
    },
  },
};
</script>
