export function isValidRtspUrl(url) {
  try {
    const parsedUrl = new URL(url);
    return ['rtsp:'].includes(parsedUrl.protocol);
  } catch (e) {
    return false;
  }
}

export function isValidHttpUrl(url) {
  try {
    const parsedUrl = new URL(url);
    return ['http:'].includes(parsedUrl.protocol) || ['https:'].includes(parsedUrl.protocol);
  } catch (e) {
    return false;
  }
}
